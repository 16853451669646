<template>
  <div class="partner-dashboard">
    <PartnerDashboarHeader />
    <DashboardNavigation />
    <nuxt  />
    <PartnerDashboarFooter />
  </div>
</template>

<script>

import PartnerDashboarHeader from '~/components/partner-dashboard-header'
import PartnerDashboarFooter from '~/components/partner-dashboard-footer'
import DashboardNavigation from '~/components/dashboard-navigation'

export default {

  head () {
    return {
      script: [
        { src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js' }
      ],
      link: [
        {  rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Montserrat:400,500,700,800|Roboto:400,500,700' }
      ]
    }
  },
  data() {
    return {

    }
  },

  components: {
    PartnerDashboarHeader,
    PartnerDashboarFooter,
    DashboardNavigation
  },
  beforeMount() {


  }
}
</script>

<style lang="scss">
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  background-color: #fff;
}
body {
  height: 100%;
}

h1,h2,h3,h4,h5,h6,button {
  font-family: 'Montserrat', sans-serif;
  font-weight:bold;
  letter-spacing:1px;
}


p,a,select,input,li,span {
  font-family:'roboto',sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

a {
  &:hover {
    text-decoration: none;
    color: #fa5b33;
    cursor: pointer;
  }
}

.partner-dashboard {
  height:100%;
  position:relative;
}

</style>

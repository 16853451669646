window.gapiOnLoadCallback = () => {
  window.gapi.load('auth2', () => {
    window.google_auth2 = window.gapi.auth2.init({
      	client_id: '102478082170-0i4r8ff2aif62ngh41v3kc4t147l1lkf.apps.googleusercontent.com',
		apiKey: 'AIzaSyCDRiYrmu--wI5sauWXDgyZ72Ra9kmnZMw',
		scopes: "https://www.googleapis.com/auth/userinfo.email"
    })
  })
  this.google_ready = true
}
const installGoogleSdkScript = (d, s, id) => {
  if (d.getElementById(id)) {
    this.google_sdk_initialized = true
    return
  }
  let fjs = d.getElementsByTagName(s)[0]
  let js = d.createElement(s)
  js.id = id
  js.src = 'https://apis.google.com/js/platform.js?onload=gapiOnLoadCallback'
  fjs.parentNode.insertBefore(js, fjs)
}
installGoogleSdkScript(document, 'script', 'google-jssdk')
<template>
  <footer class="main-footer">
    <b-container>
      <div class="f-top">
        <b-row>
          <b-col lg="5" md="12">
            <div class="footer-logo">
              <img class="logo" src="/images/logo_footer.png">
            </div>
            <p>
              {{ $t('footer.about') }}
            </p>
          </b-col>
          <b-col lg="2" md="12">
          </b-col>
          <!-- <b-col lg="5" md="12" class="newsletter">
            <h4>Newsletter</h4>
            <p>Subscribe to our newsletter to stay up-to-date with Indonesia’s docking industry news, events, and prizes.</p>
            <b-row>
              <b-col lg="8" class=" pdr-0">
                <b-form-group>
                  <b-form-input v-model="newsletterEmail" type="text" placeholder="Enter email address"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="4" class="pdl-5">
                <b-form-group>
                  <b-button @click="registerNewsletter">Subscribe</b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col> -->
          <b-col class="mtop-20" lg="5" md="12">
            <h4>Our Location</h4>
            <p class="office">
               <br>
               18 Office Park, Lt. 25, Suite A 2<br>
               Jl. TB Simatupang Kav. 18<br>
               Jakarta - Indonesia
            </p>
          </b-col>
          <b-col lg="8" md="12" class="supported-by">
            <div class="supported-by">
              <h4 class="mtop-20">Collaborated with</h4>
              <b-row>
                <b-col lg="4" md="12">
                  <img src="/images/kemenhub.png" alt="">
                </b-col>
                <b-col lg="4" md="12">
                  <img class="btkp" src="/images/LOGO-BTKP.png" alt="">
                </b-col>
              </b-row>

            </div>
          </b-col>
          <b-col lg="8" md="12" class="supported-by">
            <div class="supported-by">
              <h4 class="mtop-20">Supported By</h4>
              <b-row>
                <b-col lg="4" md="12">
                  <img class="alfatek" src="/images/logo-alfatek.png" alt="">
                </b-col>
              </b-row>

            </div>
          </b-col>
        </b-row>
      </div>
      <div class="f-mid">
        <b-row>
          <b-col lg="2" md="6" sm="6" cols="6">
            <ul>
              <li class="heading-menu">General</li>
              <li><a href="/about">About</a></li>
              <li><a href="/galangan">Explore</a></li>
              <li><a href="http://blog.docking.id/">Blogs</a></li>
            </ul>
          </b-col>
          <b-col lg="2" md="6" sm="6" cols="6">
            <ul>
              <li class="heading-menu">For Partners</li>
              <li><a href="/become-partner">Become a partner</a></li>
              <li><a href="/vendor">Vessel Needs</a></li>
              <li><a href="http://blog.docking.id/">Events</a></li>
            </ul>
          </b-col>
          <b-col lg="2" md="6" sm="6" cols="6">
            <ul>
              <li class="heading-menu">Supports</li>
              <li><a href="mailto:halo@docking.id">Contact Us</a></li>
              <li><a href="/about">Knowledge Base</a></li>
            </ul>
          </b-col>
          <b-col lg="6" md="6" sm="6" cols="12">


            <ul class="sosmed-icons">
              <li class="fb">
                <a href="https://www.facebook.com/docking.id/"><img src="/images/icons/facebook.svg"></a>
              </li>
              <li class="linkedin">
                <a href="https://www.linkedin.com/company/docking-id/"><img src="/images/icons/linkedin.svg"></a>
              </li>
              <li class="wa">
                <a href="https://api.whatsapp.com/send?phone=6281295009910&text=halo, saya pengunjung website mau menanyakan soal docking.id?"><img src="/images/icons/whatsapp.svg"></a>
              </li>
            </ul>
          </b-col>
        </b-row>
      </div>
      <div class="f-btm">
        <b-row>
          <b-col lg="5" md="12" cols="12">
            <p class="copyright">Copyright &copy; 2020 PT Laut Indonesiaku Biru All rights reserved</p>
          </b-col>
          <b-col lg="7" md="12" cols="12">
            <ul>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
              <li><a href="/terms-and-conditions">Terms & Conditions</a></li>
              <li><a href="">Site Map</a></li>
              <li>
                <select class="form-select">
                    <option>ID</option>
                </select>
              </li>
            </ul>
          </b-col>
        </b-row>

      </div>
    </b-container>

  </footer>
</template>

<script>
  import miscAPI from '@/api/misc.js'
  export default{
    data(){
      return {
        newsletterEmail: null
      }
    },
    methods: {
      registerNewsletter() {
        let miscApi = new miscAPI(this.$axios);

        miscApi.applyNewsletter({email: this.newsletterEmail}).then(res => {
          alert('done');
          window.location.reload();
        })
      }
    }
  }
</script>

<style lang="scss">
@import '~/assets/scss/_mixins.scss';
@import './style.scss';
</style>

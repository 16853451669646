const store = {
	state: () => ({
		blogs: []
	}),
	getters: {

	},
	mutations: {
		SET_BLOGS(state, blogs) {
			state.blogs = blogs
		}
	},
	actions: {
		setBlogs: ({commit}, blogs) => {
			
			//console.log("setBlogs", blogs);
			commit('SET_BLOGS', blogs)
		},
	}
}

export default store

import Cookie from "js-cookie";

export default class AccountAPI {
  constructor($axios) {
    this.$axios = $axios;
    this.baseURL = process.env.BACKEND_URL;
  }

  doLogin(data) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/login`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  register(data) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/register`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  verify(data) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/verify`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  me() {
    //console.log("DOCKSID ==> ", Cookie.get("DOCKSID"));
    return this.$axios.request({
      url: `${this.baseURL}/v1/me`,
      method: "GET",
      headers: {
        "Content-Type": "Application/json",
        "Session-id": Cookie.get("DOCKSID")
      }
    });
  }

  get(slug) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/Account/${slug}`,
      method: "GET",
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  update(data, accountId) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/account/update/${accountId}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  checkAccountIdAndPassword(accountId, password) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/account/checkPassword/${accountId}`,
      method: "PUT",
      data: {
        password: password
      },
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }
}

const store = {
	state: () => ({
		filters: {
			city: null
		}
	}),
	getters: {

	},
	mutations: {
		SET_FILTERS(state, filters) {
			state.filters = filters
		}
	},
	actions: {
		setFilters: ({commit}, filters) => {
			commit('SET_FILTERS', filters)
		},
	}
}

export default store

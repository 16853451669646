import Cookie from 'js-cookie'

export default function ({app, store, redirect, route, params}) {
    
    let session = app.$cookies.get('DOCKSID')
    let usertype = app.$cookies.get("USERTYPE")
    let loginRoute = route.name // "lang-login"
    
    if(!session && loginRoute != "lang-login") {
        return redirect("/login")
        //console.log("hello redirect => ",loginRoute, "session => ", session)
    }

    if(usertype === "vendor") {
        //console.log("hello there", route.name)
        if( route.name === "lang-partner-dashboard-shipyard-facilities") {
            return redirect("/partner-dashboard")
        }
    } else if(usertype === "shipyard") {

    } else if (usertype === "user") {
        if( route.name === "lang-partner-dashboard-shipyard-facilities"
        || route.name === "lang-partner-dashboard") {
            return redirect("/user-dashboard")
        }
    }

    if(loginRoute === "lang-login" && usertype) {
        
        if(usertype === "vendor") {
            return redirect("/partner-dashboard")
        }
        else if( usertype === "shipyard") {
            return redirect("/partner-dashboard/shipyard-facilities")
        } else if( usertype === "user") {
            return redirect("/user-dashboard")
        }
    }
    
}
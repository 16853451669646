<template>
	<b-modal id="modalLogin" class="modal-login" centered size="lg" ok-only>
		<b-row>
			<b-col lg="6" class="bg-image">
				<h3>All aboard to Docking.id!</h3>
				<p>
					<mark>Join with us and get access to a hundred shipyards and maritime vendors in Indonesia!</mark>
				</p>
				<ul class="sosmed-icons">
					<li class="fb">
						<a href="https://www.facebook.com/docking.id/"><img src="/images/icons/facebook_white.png"></a>
					</li>
					<li class="ig">
						<a href="#"><img src="/images/icons/instagram_white.png"></a>
					</li>
					<li class="linkedin">
						<a href="https://www.linkedin.com/company/docking-id/"><img src="/images/icons/linkedin_white.png"></a>
					</li>
					<li class="wa">
						<a href="https://api.whatsapp.com/send?phone=6282112845204&text=halo, salam kenal saya Fadil dari docking.id ada yang bisa kami bantu ?"><img src="/images/icons/whatsapp_white.png"></a>
					</li>
				</ul>
			</b-col>
			<b-col lg="6">

				<b-tabs content-class="mt-3">

					<b-tab title="Login" active @click="isLogin=true">
						<b-form class="login-form" :class="{'was-validated': logging}">
							<b-form-group label-for="email">
								<b-form-input type="email" v-model="email" required placeholder="E-mail Address" />
								<div class="invalid-feedback">
				                  Please fill.
				                </div>
							</b-form-group>
							<b-form-group label-for="password">
								<b-form-input type="password" v-model="password" required placeholder="Password" />
								<div class="invalid-feedback">
				                  Please fill.
				                </div>
							</b-form-group>
							<b-alert v-if="passwordError" show variant="danger">Wrong Email / Password</b-alert>
							<div class="form-opt">
								<a @click="login()" class="btn-primary">Login</a>
								<p href="#" class="lupa">Forget password? <a href="#">klik here</a></p>
							</div>
						</b-form>
						<!-- <p class="atau">
							<span>Or login with</span>
						</p>
						<div class="">
							<a @click="facebookLogin" class="btn facebook">Facebook</a>
							<a @click="googleLogin" class="btn google">Gmail</a>
						</div> -->
					</b-tab>

					<b-tab title="Daftar" @click="isLogin=false">
						<b-form class="regis-form">
							<b-form-group label-for="name">
								<b-form-input id="name" type="text" v-model="name" required placeholder="Your name" />
							</b-form-group>

							<b-form-group label-for="email">
								<b-form-input type="email" v-model="email" required placeholder="E-mail address" />
							</b-form-group>

							<p class="term">Dengan sign up, Anda telah menyetujui <a href="#">Syarat & Ketentuan</a> </p>
							<div class="form-opt">
								<a @click="register()" class="btn-primary">Daftar</a>
							</div>
						</b-form>

						<p class="atau">
							<span>Atau daftar dengan</span>
						</p>

						<div class="">
							<a @click="facebookLogin" class="btn facebook">Facebook</a>
							<a @click="googleLogin" class="btn google">Gmail</a>
						</div>
					</b-tab>

				</b-tabs>

			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
import accountAPI from '~/api/account.js'
import Cookie from 'js-cookie'

export default {
	data() {
		return {
			email: null,
			password: null,
			socmed: null,
			name: null,
			show: true,
			isLogin: true,
			passwordError: false,
			logging: false
		}
	},
	methods: {
		onSubmit(evt) {
			evt.preventDefault()
			alert(JSON.stringify(this.form))
		},
		login(attr=null) {
	  		let accountApi = new accountAPI(this.$axios);
	  		let loginPayload = {};
			if(attr != null) {
				loginPayload = {
					email : attr.email,
					socmed: attr.type
				}
			} else {
				loginPayload = {
					email : this.email,
					password: this.password
				}
			}

			this.logging = true;

			if(loginPayload.email && (loginPayload.socmed || loginPayload.password)) {
				accountApi.doLogin(loginPayload).then(res => {

					if(res.data.type === "vendor") {
						Cookie.set('DOCKSID',res.data.session_id);
						Cookie.set("USERTYPE",res.data.type)
						this.$store.dispatch('setAccount', res.data);
						window.location.pathname = "partner-dashboard"
					} else if(res.data.type === "shipyard") {
						Cookie.set('DOCKSID',res.data.session_id);
						Cookie.set("USERTYPE",res.data.type)
						//alert(JSON.stringify(Cookie.get()))
						// console.log("login process => ",res.data)
						// alert(JSON.stringify(res.data))
						this.$store.dispatch('setAccount', res.data);
						window.location.pathname = "/partner-dashboard/shipyard-facilities"
					} else {
						if(res.data.status == 1) { //already registered
							Cookie.set('DOCKSID',res.data.session_id);
							Cookie.set("USERTYPE",res.data.type)
							this.$store.dispatch('setAccount', res.data);
						} else {
							Cookie.set('tempEmailReg',res.data.email);
							window.location.pathname = 'create-account';
						}
					}


					this.$emit('close');

				}).catch(err => {
					if(err.response.status == 404) {
						if(this.isLogin) {
							alert('email is not registered, please register');
						} else {
							this.register(attr); //only after login with socmed
						}
					}

					if(err.response.status == 401) {
						if(err.response.data.code == 5) this.passwordError = true;
					}
				})
			}
		},
		register(attr) {
			if(attr != null) {
				let regPayload = {
					email : attr.email,
					socmed: attr.type,
					name: attr.fullName,
					password: null,
					type: 'user'
				}
				Cookie.set('tempReg', JSON.stringify(regPayload));
			} else {
				Cookie.set('tempReg', JSON.stringify({email: this.email, name: this.name}));
			}

			window.location.pathname = 'create-account';
			this.$emit('close');
		},
		facebookLogin(){
			let users = [];
			let payload = {};
			let _this = this;
			function authenticate(callback, errorCallback) {
				FB.getLoginStatus(function(response) {
					if (response.status === 'connected')
					{
						callback(response.authResponse);
					} else {
						FB.login(function(response)
						{
							if (response.authResponse)
							{
								callback(response.authResponse);
							} else {
								errorCallback && errorCallback(response);
								// resolve(response.error, null, deferred);
							}
						}, { scope: 'email' });
					}
				});
			}

			authenticate(function(authDetail){
				FB.api('/me?fields=id,name,email,picture,token_for_business&scope=email', function(response) {
					users['users_detail'] = response;
        			users['auth_detail'] = authDetail;

					payload = {
						email : response.email || _this.email,
						fullName : response.name,
						type : 'facebook'
					}
					if(payload.email){
						setTimeout(()=>{
						_this.login(payload)
						}, 1000)
					}
					// remove verified check
					// reason : https://www.facebook.com/help/196050490547892
					if (!response || response.error)
					{
						// resolve(response.error, null, deferred);
					}
				});
			}, function(error){

			});
		},
		googleLogin() {
			let data;
			let payload = {};
			let _this = this;
			window.google_auth2.signIn().then(() => {
				data = window.google_auth2.currentUser.get();
				payload = {
					// id : data.El,
					email : data.w3.U3,
					fullName : data.w3.ofa + ' '+data.w3.wea || data.w3.U3.substring(0, data.w3.U3.indexOf("@")),
					type : 'google',
            		// access_token : data.Zi.access_token,
				}
				this.login(payload);
			}).catch((err)=>{
				this.isLoading = false;
			})
		},
		onReset(evt) {
			evt.preventDefault()
			/* Reset our form values */
			this.email = ''
			this.password = ''
			/* Trick to reset/clear native browser form validation state */
			this.show = false
			this.$nextTick(() => {
				this.show = true
			})
		}
	}
}
</script>
<style lang="scss">
@import '~/assets/scss/_mixins.scss';
@import './style.scss';
</style>

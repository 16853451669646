import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _d8a8f054 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _34b1b9af = () => interopDefault(import('../pages/become-partner/index.vue' /* webpackChunkName: "pages/become-partner/index" */))
const _999675e2 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _10202e6e = () => interopDefault(import('../pages/create-account/index.vue' /* webpackChunkName: "pages/create-account/index" */))
const _f48fa6bc = () => interopDefault(import('../pages/galangan/index.vue' /* webpackChunkName: "pages/galangan/index" */))
const _3e42e1e0 = () => interopDefault(import('../pages/imatap-webinar/index.vue' /* webpackChunkName: "pages/imatap-webinar/index" */))
const _43062b9c = () => interopDefault(import('../pages/internship-program/index.vue' /* webpackChunkName: "pages/internship-program/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _8293d6d0 = () => interopDefault(import('../pages/partner-dashboard/index.vue' /* webpackChunkName: "pages/partner-dashboard/index" */))
const _52932d4f = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _7de68bc4 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _358e7c43 = () => interopDefault(import('../pages/safety-sailing/index.vue' /* webpackChunkName: "pages/safety-sailing/index" */))
const _00175dac = () => interopDefault(import('../pages/shipyard-dashboard/index.vue' /* webpackChunkName: "pages/shipyard-dashboard/index" */))
const _367d838e = () => interopDefault(import('../pages/shipyard-registration/index.vue' /* webpackChunkName: "pages/shipyard-registration/index" */))
const _794dd4e6 = () => interopDefault(import('../pages/success-activation/index.vue' /* webpackChunkName: "pages/success-activation/index" */))
const _1a4a7320 = () => interopDefault(import('../pages/success-registration/index.vue' /* webpackChunkName: "pages/success-registration/index" */))
const _fe62773e = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _caad3c02 = () => interopDefault(import('../pages/user-dashboard/index.vue' /* webpackChunkName: "pages/user-dashboard/index" */))
const _0891f6b5 = () => interopDefault(import('../pages/vendor/index.vue' /* webpackChunkName: "pages/vendor/index" */))
const _549a87a7 = () => interopDefault(import('../pages/vendor-registration/index.vue' /* webpackChunkName: "pages/vendor-registration/index" */))
const _3b98f888 = () => interopDefault(import('../pages/galangan/profile/index.vue' /* webpackChunkName: "pages/galangan/profile/index" */))
const _7063c00e = () => interopDefault(import('../pages/partner-dashboard/account-setting/index.vue' /* webpackChunkName: "pages/partner-dashboard/account-setting/index" */))
const _5c7712f5 = () => interopDefault(import('../pages/partner-dashboard/company-images/index.vue' /* webpackChunkName: "pages/partner-dashboard/company-images/index" */))
const _65affede = () => interopDefault(import('../pages/partner-dashboard/docking-package/index.vue' /* webpackChunkName: "pages/partner-dashboard/docking-package/index" */))
const _4f833cfe = () => interopDefault(import('../pages/partner-dashboard/shipyard-facilities/index.vue' /* webpackChunkName: "pages/partner-dashboard/shipyard-facilities/index" */))
const _9bdab02e = () => interopDefault(import('../pages/success-registration/partner/index.vue' /* webpackChunkName: "pages/success-registration/partner/index" */))
const _64fd5dc9 = () => interopDefault(import('../pages/user-dashboard/company-profile/index.vue' /* webpackChunkName: "pages/user-dashboard/company-profile/index" */))
const _7cb78acf = () => interopDefault(import('../pages/vendor/profile/index.vue' /* webpackChunkName: "pages/vendor/profile/index" */))
const _03242ec2 = () => interopDefault(import('../pages/blog/category/_category.vue' /* webpackChunkName: "pages/blog/category/_category" */))
const _e5e4b0a4 = () => interopDefault(import('../pages/blog/search/_keyword.vue' /* webpackChunkName: "pages/blog/search/_keyword" */))
const _71b29760 = () => interopDefault(import('../pages/blog-detail/_slug/index.vue' /* webpackChunkName: "pages/blog-detail/_slug/index" */))
const _15b035c6 = () => interopDefault(import('../pages/galangan/_city/index.vue' /* webpackChunkName: "pages/galangan/_city/index" */))
const _6dced620 = () => interopDefault(import('../pages/vendor/_city/index.vue' /* webpackChunkName: "pages/vendor/_city/index" */))
const _7a6d842b = () => interopDefault(import('../pages/galangan/_city/_category/index.vue' /* webpackChunkName: "pages/galangan/_city/_category/index" */))
const _310ec83e = () => interopDefault(import('../pages/vendor/_city/_category/index.vue' /* webpackChunkName: "pages/vendor/_city/_category/index" */))
const _fa881e16 = () => interopDefault(import('../pages/galangan/_city/_category/_type/index.vue' /* webpackChunkName: "pages/galangan/_city/_category/_type/index" */))
const _4c65eaec = () => interopDefault(import('../pages/galangan/_city/_category/_type/_material/index.vue' /* webpackChunkName: "pages/galangan/_city/_category/_type/_material/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3c08c1d4 = () => interopDefault(import('../pages/_lang/index.vue' /* webpackChunkName: "pages/_lang/index" */))
const _4bf3ebd8 = () => interopDefault(import('../pages/_lang/about/index.vue' /* webpackChunkName: "pages/_lang/about/index" */))
const _2e5a459e = () => interopDefault(import('../pages/_lang/become-partner/index.vue' /* webpackChunkName: "pages/_lang/become-partner/index" */))
const _52fbf9de = () => interopDefault(import('../pages/_lang/blog/index.vue' /* webpackChunkName: "pages/_lang/blog/index" */))
const _a7dde76a = () => interopDefault(import('../pages/_lang/create-account/index.vue' /* webpackChunkName: "pages/_lang/create-account/index" */))
const _b75accb8 = () => interopDefault(import('../pages/_lang/galangan/index.vue' /* webpackChunkName: "pages/_lang/galangan/index" */))
const _d6009adc = () => interopDefault(import('../pages/_lang/imatap-webinar/index.vue' /* webpackChunkName: "pages/_lang/imatap-webinar/index" */))
const _e3070698 = () => interopDefault(import('../pages/_lang/internship-program/index.vue' /* webpackChunkName: "pages/_lang/internship-program/index" */))
const _3e229a20 = () => interopDefault(import('../pages/_lang/login/index.vue' /* webpackChunkName: "pages/_lang/login/index" */))
const _4dd26bc6 = () => interopDefault(import('../pages/_lang/maps/index.vue' /* webpackChunkName: "pages/_lang/maps/index" */))
const _c9cdac54 = () => interopDefault(import('../pages/_lang/partner-dashboard/index.vue' /* webpackChunkName: "pages/_lang/partner-dashboard/index" */))
const _6c56180d = () => interopDefault(import('../pages/_lang/pricing/index.vue' /* webpackChunkName: "pages/_lang/pricing/index" */))
const _3207af46 = () => interopDefault(import('../pages/_lang/privacy-policy/index.vue' /* webpackChunkName: "pages/_lang/privacy-policy/index" */))
const _2ca0c076 = () => interopDefault(import('../pages/_lang/safety-sailing/index.vue' /* webpackChunkName: "pages/_lang/safety-sailing/index" */))
const _a01838a8 = () => interopDefault(import('../pages/_lang/shipyard-dashboard/index.vue' /* webpackChunkName: "pages/_lang/shipyard-dashboard/index" */))
const _02fb8477 = () => interopDefault(import('../pages/_lang/shipyard-registration/index.vue' /* webpackChunkName: "pages/_lang/shipyard-registration/index" */))
const _7358a80f = () => interopDefault(import('../pages/_lang/success-activation/index.vue' /* webpackChunkName: "pages/_lang/success-activation/index" */))
const _bd807f1c = () => interopDefault(import('../pages/_lang/success-registration/index.vue' /* webpackChunkName: "pages/_lang/success-registration/index" */))
const _2f33be63 = () => interopDefault(import('../pages/_lang/terms-and-conditions/index.vue' /* webpackChunkName: "pages/_lang/terms-and-conditions/index" */))
const _3e2ea7fe = () => interopDefault(import('../pages/_lang/test/index.vue' /* webpackChunkName: "pages/_lang/test/index" */))
const _4eca8581 = () => interopDefault(import('../pages/_lang/user-dashboard/index.vue' /* webpackChunkName: "pages/_lang/user-dashboard/index" */))
const _e4f08792 = () => interopDefault(import('../pages/_lang/vendor/index.vue' /* webpackChunkName: "pages/_lang/vendor/index" */))
const _b6e57536 = () => interopDefault(import('../pages/_lang/vendor-registration/index.vue' /* webpackChunkName: "pages/_lang/vendor-registration/index" */))
const _1299cebe = () => interopDefault(import('../pages/_lang/galangan/profile/index.vue' /* webpackChunkName: "pages/_lang/galangan/profile/index" */))
const _12ea0d92 = () => interopDefault(import('../pages/_lang/partner-dashboard/account-setting/index.vue' /* webpackChunkName: "pages/_lang/partner-dashboard/account-setting/index" */))
const _663b19f7 = () => interopDefault(import('../pages/_lang/partner-dashboard/company-images/index.vue' /* webpackChunkName: "pages/_lang/partner-dashboard/company-images/index" */))
const _08364c62 = () => interopDefault(import('../pages/_lang/partner-dashboard/docking-package/index.vue' /* webpackChunkName: "pages/_lang/partner-dashboard/docking-package/index" */))
const _3e506bbf = () => interopDefault(import('../pages/_lang/partner-dashboard/shipyard-facilities/index.vue' /* webpackChunkName: "pages/_lang/partner-dashboard/shipyard-facilities/index" */))
const _8d33002a = () => interopDefault(import('../pages/_lang/success-registration/partner/index.vue' /* webpackChunkName: "pages/_lang/success-registration/partner/index" */))
const _3289856a = () => interopDefault(import('../pages/_lang/user-dashboard/company-profile/index.vue' /* webpackChunkName: "pages/_lang/user-dashboard/company-profile/index" */))
const _30d8ae51 = () => interopDefault(import('../pages/_lang/vendor/profile/index.vue' /* webpackChunkName: "pages/_lang/vendor/profile/index" */))
const _4a5e0446 = () => interopDefault(import('../pages/_lang/blog/category/_category.vue' /* webpackChunkName: "pages/_lang/blog/category/_category" */))
const _92bba49a = () => interopDefault(import('../pages/_lang/blog/search/_keyword/index.vue' /* webpackChunkName: "pages/_lang/blog/search/_keyword/index" */))
const _10e32d76 = () => interopDefault(import('../pages/_lang/galangan/profile/_slug.vue' /* webpackChunkName: "pages/_lang/galangan/profile/_slug" */))
const _2f220d09 = () => interopDefault(import('../pages/_lang/vendor/profile/_slug.vue' /* webpackChunkName: "pages/_lang/vendor/profile/_slug" */))
const _4e15ac9e = () => interopDefault(import('../pages/_lang/blog-detail/_slug/index.vue' /* webpackChunkName: "pages/_lang/blog-detail/_slug/index" */))
const _ad6deec2 = () => interopDefault(import('../pages/_lang/galangan/_city/index.vue' /* webpackChunkName: "pages/_lang/galangan/_city/index" */))
const _0ad0b0f2 = () => interopDefault(import('../pages/_lang/vendor/_city/index.vue' /* webpackChunkName: "pages/_lang/vendor/_city/index" */))
const _19e1ad2d = () => interopDefault(import('../pages/_lang/galangan/_city/_category/index.vue' /* webpackChunkName: "pages/_lang/galangan/_city/_category/index" */))
const _4bc56c80 = () => interopDefault(import('../pages/_lang/vendor/_city/_category/index.vue' /* webpackChunkName: "pages/_lang/vendor/_city/_category/index" */))
const _0479d077 = () => interopDefault(import('../pages/_lang/galangan/_city/_category/_type/index.vue' /* webpackChunkName: "pages/_lang/galangan/_city/_category/_type/index" */))
const _59bbcfee = () => interopDefault(import('../pages/_lang/galangan/_city/_category/_type/_material/index.vue' /* webpackChunkName: "pages/_lang/galangan/_city/_category/_type/_material/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/about",
      component: _d8a8f054,
      name: "about"
    }, {
      path: "/become-partner",
      component: _34b1b9af,
      name: "become-partner"
    }, {
      path: "/blog",
      component: _999675e2,
      name: "blog"
    }, {
      path: "/create-account",
      component: _10202e6e,
      name: "create-account"
    }, {
      path: "/galangan",
      component: _f48fa6bc,
      name: "galangan"
    }, {
      path: "/imatap-webinar",
      component: _3e42e1e0,
      name: "imatap-webinar"
    }, {
      path: "/internship-program",
      component: _43062b9c,
      name: "internship-program"
    }, {
      path: "/login",
      component: _cad79e9c,
      name: "login"
    }, {
      path: "/partner-dashboard",
      component: _8293d6d0,
      name: "partner-dashboard"
    }, {
      path: "/pricing",
      component: _52932d4f,
      name: "pricing"
    }, {
      path: "/privacy-policy",
      component: _7de68bc4,
      name: "privacy-policy"
    }, {
      path: "/safety-sailing",
      component: _358e7c43,
      name: "safety-sailing"
    }, {
      path: "/shipyard-dashboard",
      component: _00175dac,
      name: "shipyard-dashboard"
    }, {
      path: "/shipyard-registration",
      component: _367d838e,
      name: "shipyard-registration"
    }, {
      path: "/success-activation",
      component: _794dd4e6,
      name: "success-activation"
    }, {
      path: "/success-registration",
      component: _1a4a7320,
      name: "success-registration"
    }, {
      path: "/terms-and-conditions",
      component: _fe62773e,
      name: "terms-and-conditions"
    }, {
      path: "/user-dashboard",
      component: _caad3c02,
      name: "user-dashboard"
    }, {
      path: "/vendor",
      component: _0891f6b5,
      name: "vendor"
    }, {
      path: "/vendor-registration",
      component: _549a87a7,
      name: "vendor-registration"
    }, {
      path: "/galangan/profile",
      component: _3b98f888,
      name: "galangan-profile"
    }, {
      path: "/partner-dashboard/account-setting",
      component: _7063c00e,
      name: "partner-dashboard-account-setting"
    }, {
      path: "/partner-dashboard/company-images",
      component: _5c7712f5,
      name: "partner-dashboard-company-images"
    }, {
      path: "/partner-dashboard/docking-package",
      component: _65affede,
      name: "partner-dashboard-docking-package"
    }, {
      path: "/partner-dashboard/shipyard-facilities",
      component: _4f833cfe,
      name: "partner-dashboard-shipyard-facilities"
    }, {
      path: "/success-registration/partner",
      component: _9bdab02e,
      name: "success-registration-partner"
    }, {
      path: "/user-dashboard/company-profile",
      component: _64fd5dc9,
      name: "user-dashboard-company-profile"
    }, {
      path: "/vendor/profile",
      component: _7cb78acf,
      name: "vendor-profile"
    }, {
      path: "/blog/category/:category?",
      component: _03242ec2,
      name: "blog-category-category"
    }, {
      path: "/blog/search/:keyword?",
      component: _e5e4b0a4,
      name: "blog-search-keyword"
    }, {
      path: "/blog-detail/:slug",
      component: _71b29760,
      name: "blog-detail-slug"
    }, {
      path: "/galangan/:city",
      component: _15b035c6,
      name: "galangan-city"
    }, {
      path: "/vendor/:city",
      component: _6dced620,
      name: "vendor-city"
    }, {
      path: "/galangan/:city/:category",
      component: _7a6d842b,
      name: "galangan-city-category"
    }, {
      path: "/vendor/:city/:category",
      component: _310ec83e,
      name: "vendor-city-category"
    }, {
      path: "/galangan/:city/:category/:type",
      component: _fa881e16,
      name: "galangan-city-category-type"
    }, {
      path: "/galangan/:city/:category/:type/:material",
      component: _4c65eaec,
      name: "galangan-city-category-type-material"
    }, {
      path: "/",
      component: _2dfb1658,
      name: "index"
    }, {
      path: "/:lang",
      component: _3c08c1d4,
      name: "lang"
    }, {
      path: "/:lang/about",
      component: _4bf3ebd8,
      name: "lang-about"
    }, {
      path: "/:lang/become-partner",
      component: _2e5a459e,
      name: "lang-become-partner"
    }, {
      path: "/:lang/blog",
      component: _52fbf9de,
      name: "lang-blog"
    }, {
      path: "/:lang/create-account",
      component: _a7dde76a,
      name: "lang-create-account"
    }, {
      path: "/:lang/galangan",
      component: _b75accb8,
      name: "lang-galangan"
    }, {
      path: "/:lang/imatap-webinar",
      component: _d6009adc,
      name: "lang-imatap-webinar"
    }, {
      path: "/:lang/internship-program",
      component: _e3070698,
      name: "lang-internship-program"
    }, {
      path: "/:lang/login",
      component: _3e229a20,
      name: "lang-login"
    }, {
      path: "/:lang/maps",
      component: _4dd26bc6,
      name: "lang-maps"
    }, {
      path: "/:lang/partner-dashboard",
      component: _c9cdac54,
      name: "lang-partner-dashboard"
    }, {
      path: "/:lang/pricing",
      component: _6c56180d,
      name: "lang-pricing"
    }, {
      path: "/:lang/privacy-policy",
      component: _3207af46,
      name: "lang-privacy-policy"
    }, {
      path: "/:lang/safety-sailing",
      component: _2ca0c076,
      name: "lang-safety-sailing"
    }, {
      path: "/:lang/shipyard-dashboard",
      component: _a01838a8,
      name: "lang-shipyard-dashboard"
    }, {
      path: "/:lang/shipyard-registration",
      component: _02fb8477,
      name: "lang-shipyard-registration"
    }, {
      path: "/:lang/success-activation",
      component: _7358a80f,
      name: "lang-success-activation"
    }, {
      path: "/:lang/success-registration",
      component: _bd807f1c,
      name: "lang-success-registration"
    }, {
      path: "/:lang/terms-and-conditions",
      component: _2f33be63,
      name: "lang-terms-and-conditions"
    }, {
      path: "/:lang/test",
      component: _3e2ea7fe,
      name: "lang-test"
    }, {
      path: "/:lang/user-dashboard",
      component: _4eca8581,
      name: "lang-user-dashboard"
    }, {
      path: "/:lang/vendor",
      component: _e4f08792,
      name: "lang-vendor"
    }, {
      path: "/:lang/vendor-registration",
      component: _b6e57536,
      name: "lang-vendor-registration"
    }, {
      path: "/:lang/galangan/profile",
      component: _1299cebe,
      name: "lang-galangan-profile"
    }, {
      path: "/:lang/partner-dashboard/account-setting",
      component: _12ea0d92,
      name: "lang-partner-dashboard-account-setting"
    }, {
      path: "/:lang/partner-dashboard/company-images",
      component: _663b19f7,
      name: "lang-partner-dashboard-company-images"
    }, {
      path: "/:lang/partner-dashboard/docking-package",
      component: _08364c62,
      name: "lang-partner-dashboard-docking-package"
    }, {
      path: "/:lang/partner-dashboard/shipyard-facilities",
      component: _3e506bbf,
      name: "lang-partner-dashboard-shipyard-facilities"
    }, {
      path: "/:lang/success-registration/partner",
      component: _8d33002a,
      name: "lang-success-registration-partner"
    }, {
      path: "/:lang/user-dashboard/company-profile",
      component: _3289856a,
      name: "lang-user-dashboard-company-profile"
    }, {
      path: "/:lang/vendor/profile",
      component: _30d8ae51,
      name: "lang-vendor-profile"
    }, {
      path: "/:lang/blog/category/:category?",
      component: _4a5e0446,
      name: "lang-blog-category-category"
    }, {
      path: "/:lang/blog/search/:keyword?",
      component: _92bba49a,
      name: "lang-blog-search-keyword"
    }, {
      path: "/:lang/galangan/profile/:slug",
      component: _10e32d76,
      name: "lang-galangan-profile-slug"
    }, {
      path: "/:lang/vendor/profile/:slug",
      component: _2f220d09,
      name: "lang-vendor-profile-slug"
    }, {
      path: "/:lang/blog-detail/:slug",
      component: _4e15ac9e,
      name: "lang-blog-detail-slug"
    }, {
      path: "/:lang/galangan/:city",
      component: _ad6deec2,
      name: "lang-galangan-city"
    }, {
      path: "/:lang/vendor/:city",
      component: _0ad0b0f2,
      name: "lang-vendor-city"
    }, {
      path: "/:lang/galangan/:city/:category",
      component: _19e1ad2d,
      name: "lang-galangan-city-category"
    }, {
      path: "/:lang/vendor/:city/:category",
      component: _4bc56c80,
      name: "lang-vendor-city-category"
    }, {
      path: "/:lang/galangan/:city/:category/:type",
      component: _0479d077,
      name: "lang-galangan-city-category-type"
    }, {
      path: "/:lang/galangan/:city/:category/:type/:material",
      component: _59bbcfee,
      name: "lang-galangan-city-category-type-material"
    }],

    fallback: false
  })
}

export default class CompanyAPI{
	constructor($axios) {
		this.$axios = $axios
    	this.baseURL = process.env.BACKEND_URL;
	}

	getSailingArea(params) {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/company`,
			'method': 'GET',
			'params': params,
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	applyNewsletter(data) {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/newsletter`,
			'method': 'POST',
			'data': data,
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	get(slug) {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/company/${slug}`,
			'method': 'GET',
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	getCities() {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/cities`,
			'method': 'POST',
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	searchCity(name) {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/cities`,
			'method': 'POST',
			'data': {
				search: name
			},
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	getCity(id) {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/city/${id}`,
			'method': 'GET',
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	getShipType() {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/shiptype`,
			'method': 'GET',
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	getDockMethod() {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/category`,
			'method': 'GET',
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	getDockMaterial() {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/ship-material`,
			'method': 'GET',
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	sendMessage(data) {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/message`,
			'method': 'POST',
			'data': data,
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}

	askAvaibility(data) {
		return this.$axios.request({
			'url': `${this.baseURL}/v1/ask-avaibility`,
			'method': 'POST',
			'data': data,
			'headers': {
				'Content-Type': 'Application/json'
			}
		})
	}
}
import Vue from 'vue'
import Vuex from 'vuex'
import lang from './modules/lang'
import blogs from './modules/blogs'
import account from './modules/account'
import shipyardFilter from './modules/shipyardFilter'
import shipyardRegistration from './modules/shipyardRegistration'
import vendorRegistration from "./modules/vendorRegistration"
import shipyard from "./modules/shipyard"

Vue.use(Vuex)

const store = () => new Vuex.Store({
  modules: {
    lang,
    blogs,
    account,
    shipyardFilter,
    shipyardRegistration,
    vendorRegistration,
    shipyard
  }
})

export default store

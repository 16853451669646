import firebase from 'firebase'

if (!firebase.apps.length) {
  firebase.initializeApp({
	apiKey: "AIzaSyDi94cnWf5gU8xI0CzjIaKMBN8dwCSThGQ",
	authDomain: "docking-id.firebaseapp.com",
	databaseURL: "https://docking-id.firebaseio.com",
	projectId: "docking-id",
	storageBucket: "docking-id.appspot.com",
	messagingSenderId: "102478082170"
  })
}

const storage = firebase.storage() //if use storage

export { storage }
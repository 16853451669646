const store = {
	state: () => ({
		locales: ['en', 'id'],
		locale: 'en'
	}),
	getters: {

	},
	mutations: {
		SET_LANG(state, locale) {
			if (state.locales.indexOf(locale) !== -1) {
				state.locale = locale
			}
		}
	},
	actions: {

	}
}

export default store

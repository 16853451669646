const store = {
	state: () => ({
		step1: {
			active: true,
			complete: false
		},
		step2: {
			active: false,
			complete: false
		},
		step3: {
			active: false,
			complete: false
		},
		summary: {
			active: false,
			complete: false
    },
		data: null
	}),
	getters: {

	},
	mutations: {
		SET_STEP(state, step) {
			state[step.type] = step.data
		}
	},
	actions: {
		setStep: ({commit}, step) => {
			commit('SET_STEP', step)
		},
	}
}

export default store

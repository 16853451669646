<template lang="html">
  <div class="is_unpublish">
    <div class="container">

        <div class="greating">
          <h3 class="mbtm-10"><em>Welcome to your dashboard!</em></h3>
          <p class="howto">But your company profile is not pubslished yet.<br>Complete the following steps to get your company <br> profile published <b-badge pill variant="warning">!</b-badge></p>
        </div>
        <div class="steps">
          <div class="step1">
            <h6>1. Confirm your email</h6>
            <span>Please Check your email, we send confirmation link to activate your account at docking.id</span>
          </div>
          <div class="step2">
              <h6>2. Upload Images</h6>
              <span>Add your company images or products so clients out there can conveniently know your profile company.
                <a href="/partner-dashboard/company-images" class="badge badge-warning">Click Here +</a>
              </span>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  props:["active","hasImage"],
  data:function() {
    return {

    }
  }
}
</script>

<style lang="scss">
@import '~/assets/scss/_mixins.scss';
@import './style.scss';
</style>

export default class ShipyardAPI {
  constructor($axios) {
    this.$axios = $axios;
    this.baseURL = process.env.BACKEND_URL;
  }

  getAll(data) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/shipyards`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  get(slug) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/shipyard`,
      method: "POST",
      data: {
        slug: slug
      },
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  getById(id) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/shipyard`,
      method: "POST",
      data: {
        id: id
      },
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  getAllLocation(slug) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/shipyard-locations`,
      method: "GET",
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  update(data, shipyardId) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/shipyard/${shipyardId}`,
      method: "PUT",
      data: data,
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  createImage(shipyardId, imageUrl) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/shipyard-images`,
      method: "POST",
      data: {
        shipyardId: shipyardId,
        imageUrl: imageUrl
      },
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  deleteImage(imageId) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/shipyard-image/${imageId}`,
      method: "DELETE",
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  sendEmail(content) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/shipyard/sendEmail`,
      method: "POST",
      headers: {
        "Content-Type": "Application/json"
      },
      data: content
    });
  }

  getCertificates() {}

  getAllCertificates() {}

  /*
  data : {
    companyId
    logo
    name
  }

  */

  createCustomer(data) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/customer/`,
      method: "POST",
      headers: {
        "Content-Type": "Application/json"
      },
      data: data
    });
  }

  deleteCustomer(customerId) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/customer/${customerId}`,
      method: "DELETE",
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }
  /*
    filter : {
      companyId
    }

  */
  getAllCustomer(data) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/customers`,
      method: "POST",
      data: data,
      headers: {
        "Content-Type": "Application/json"
      }
    });
  }

  test(dt) {
    return this.$axios.request({
      url: `${this.baseURL}/v1/customers`, //
      method: "GET",
      headers: {
        "Content-Type": "Application/json"
      },
      params: dt
    });
  }
}

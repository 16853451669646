export default function ({ isHMR, app, store, route, params, error, redirect }) {
	const list = ['en', 'id']
	const defaultLocale = app.i18n.fallbackLocale
	// If middleware is called from hot module replacement, ignore it
	if (isHMR) return
	// Get locale from params
	const locale = params.lang || defaultLocale
	// const locale = app.$cookies.get('BS_PreferredLang') || params.lang || defaultLocale
	if (store.state.lang.locales.indexOf(locale) === -1) {
		return error({ message: 'This page could not be found.', statusCode: 404 })
	}
	// Set locale
	store.commit('SET_LANG', locale)
	app.i18n.locale = store.state.lang.locale
	// If route is /<defaultLocale>/... -> redirect to /...
	if (locale === defaultLocale && route.fullPath.indexOf('/' + defaultLocale) === 0) {
		const toReplace = '/' + defaultLocale
		// const re = new RegExp(toReplace)
		return redirect(
			route.fullPath.replace(toReplace, '')
		)
	}

	// handle translation
	let cookiesLang = app.$cookies.get('Docking_Lang') || 'id'
	if (locale !== cookiesLang) {
		store.commit('SET_LANG', cookiesLang)
		app.i18n.locale = store.state.lang.locale
		let res = route.fullPath.split('/')
		if (list.indexOf(res[1]) > -1) {
			if (cookiesLang === defaultLocale) {
				return redirect(route.fullPath.replace(`/${res[1]}`, ''))
			} else {
				return redirect(route.fullPath.replace(`/${res[1]}`, cookiesLang))
			}
		} else {
			return redirect(`/${cookiesLang}${route.fullPath}`)
		}
	}
}

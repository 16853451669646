<template lang="html">
  <div class="partner-dashboard-footer row justify-content-center">
    <div class="col-lg-10">
      <ul>
        <li>
          <a href="/about">
            ABOUT
          </a>
        </li>
        <li>
          <a href="/galangan">
            SHIPYARD EXPLORE
          </a>
        </li>
        <li>
          <a href="/vendor">
            VENDOR EXPLORE
          </a>
        </li>
        <li>
          <a href="/privacy-policy">
            PRIVACY POLICY
          </a>
        </li>
        <li>
          <span>|</span>
          <div class="icons">
            <a class="facebook" href="https://www.facebook.com/docking.id/"></a>
            <a class="linkedin" href="https://www.linkedin.com/company/docking-id/"></a>
            <a class="wa" href="https://api.whatsapp.com/send?phone=6282112845204&text=halo, salam kenal saya Fadil dari docking.id ada yang bisa kami bantu ?"></a>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-lg-12">
        <p>Copyright © 2019 Docking.id. all rights reserved</p>
    </div>


  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
	@import '~/assets/scss/_mixins.scss';
	@import './style.scss';
</style>

const store = {
	state: () => ({
		account: null
	}),
	getters: {

	},
	mutations: {
		SET_ACCOUNT(state, account) {
			state.account = account
			//console.log("store state => ", state.account.sessionId)
		}
	},
	actions: {
		setAccount: ({commit}, account) => {
			commit('SET_ACCOUNT', account)
		},
	}
}

export default store

<template>
	<header class="main-header" id="header">

		<b-navbar toggleable="md">

			<b-navbar-brand href="/">
				<img src="/images/docking_id_logo.png" class="d-inline-block align-top header-logo" alt="DOCKING.ID LOGO"/>
			</b-navbar-brand>

			<b-navbar-toggle target="nav_collapse_menu"></b-navbar-toggle>

			<b-collapse is-nav id="nav_collapse_menu">

				<span class="NavHead">Navigation
					<b-navbar-toggle target="nav_collapse_menu"></b-navbar-toggle>
				</span>
				<b-navbar-nav>


					<b-nav-item href="/about">About</b-nav-item>
					<b-nav-item-dropdown text="Explore" right>
		        <b-dropdown-item href="/galangan">Shipyards</b-dropdown-item>
		        <b-dropdown-item href="/vendor">Marine Vendors</b-dropdown-item>
		      	</b-nav-item-dropdown>

					<b-nav-item href="http://blog.docking.id/" target="_blank">Blogs</b-nav-item>
					<!-- <b-nav-item href="/imatap-webinar">IMATAP-Webinar</b-nav-item> -->
					<b-nav-item href="/safety-sailing">#MaritimeSafetyCampaign</b-nav-item>
					<!-- <b-nav-item href="/internship-program">Internship Program</b-nav-item> -->
					<div v-if="!showDockingFeature">
						<b-nav-item href="/become-partner" v-if="!account" class="standout">  {{ $t('menu.become_partner') }}</b-nav-item>
						<b-nav-item v-else class="standout" v-b-modal.modalLogout_first>Become Partner</b-nav-item >
					</div>

				</b-navbar-nav>

				<b-navbar-nav class="ml-auto">

					<div v-if="showDockingFeature">
							<b-nav-item class="desktop-only" href="/partner-dashboard" >Go To Dashboard &nbsp; | &nbsp;</b-nav-item>
							<b-nav-item class="mobile-only" @click="logout">Logout</b-nav-item>
					</div>

					<div v-else>
						<b-nav-item @click="openLoginPopup" v-if="!account">Account &nbsp; | &nbsp;</b-nav-item>

						<b-dropdown v-else class="if-login">
							<template slot="button-content">
								<div class="login-avatar">
									<img src="/images/icons/man.svg" alt="">
								</div>
								<span class="display-name">{{account.detail.name}} &nbsp;|&nbsp;</span>
							</template>

							<div v-if="showDockingFeature">
								<b-dropdown-item href="/partner-dashboard">Dashboard</b-dropdown-item>
							</div>

							<div v-else>
								<b-dropdown-item href="/user-dashboard">Account</b-dropdown-item>
							</div>

							<b-dropdown-item @click="logout">Logout</b-dropdown-item>
						</b-dropdown>

					</div>



					<b-dropdown class="flagcontainer">
						<template slot="button-content">
							<span class="flag" :class="lang"></span> <span class="display-inline">{{lang.toUpperCase()}}</span>
						</template>
						<b-dropdown-item href="#" @click="selectLang('id')"><span class="flag id"></span> <span class="display-inline">ID</span></b-dropdown-item>
						<b-dropdown-item href="#" @click="selectLang('en')"><span class="flag en"></span> <span class="display-inline">EN</span></b-dropdown-item>
					</b-dropdown>

				</b-navbar-nav>


				<div class="footer-header">
		      <div class="f-mid">
		        <b-row>
		          <b-col lg="2" md="6" sm="6" cols="6">
		            <ul>
		              <li class="heading-menu">General</li>
		              <li><a href="/about">About</a></li>
		              <li><a href="/galangan">Explore</a></li>
		              <li><a href="/blog">Blogs</a></li>
		            </ul>
		          </b-col>
		          <b-col lg="2" md="6" sm="6" cols="6">
		            <ul>
		              <li class="heading-menu">For Partners</li>
		              <li><a href="/become-partner">Become a partner</a></li>
		              <li><a href="/vendor">Vessel Needs</a></li>
		              <li><a href="/imatap-webinar">Events</a></li>
		            </ul>
		          </b-col>
		          <b-col lg="2" md="6" sm="6" cols="6">
		            <ul>
		              <li class="heading-menu">Supports</li>
		              <li><a href="#">Contact Us</a></li>
		              <li><a href="#">Knowledge Base</a></li>
		            </ul>
		          </b-col>
		          <b-col lg="6" md="6" sm="6" cols="12">


		            <ul class="sosmed-icons">
		              <li class="fb">
		                <a href="https://www.facebook.com/docking.id/"><img src="/images/icons/facebook.svg"></a>
		              </li>
		              <li class="ig">
		                <a href="#"><img src="/images/icons/instagram.svg"></a>
		              </li>
		              <li class="linkedin">
		                <a href="https://www.linkedin.com/company/docking-id/"><img src="/images/icons/linkedin.svg"></a>
		              </li>
		              <li class="twitter">
		                <a href="#"><img src="/images/icons/twitter.svg"></a>
		              </li>
		              <li class="wa">
		                <a href="https://api.whatsapp.com/send?phone=6282112845204&text=halo, salam kenal saya Fadil dari docking.id ada yang bisa kami bantu ?"><img src="/images/icons/whatsapp.svg"></a>
		              </li>
		            </ul>
		          </b-col>
		        </b-row>
		      </div>

				  <div class="f-btm">
		        <b-row>
		          <b-col lg="5" md="12" cols="12">
		            <p class="copyright">Copyright &copy; 2019 PT Docking.id. All rights reserved</p>
		          </b-col>
		          <b-col lg="7" md="12" cols="12">
		            <ul>
		              <li><a href="/privacy-policy">Privacy Policy</a></li>
		              <li><a href="">Terms & Conditions</a></li>
		              <li><a href="">Site Map</a></li>
		              <li>
		                <select class="form-select">
		                    <option>ID</option>
		                </select>
		              </li>
		            </ul>
		          </b-col>
		        </b-row>

		      </div>

				</div>


			</b-collapse>
		</b-navbar>
		<ModalLogin @close="closeLoginPopup"/>

		<b-modal id="modalLogout_first" centered>
			<p class="my-4">Please logut first to access <strong>Become Partner</strong></p>
		</b-modal>

	</header>
</template>

<script>
import ModalLogin from '~/components/modal/login'
import accountAPI from '~/api/account.js'

import Cookie from 'js-cookie'

export default {

	data:function() {
    return {
      showDockingFeature:false
    }
  },

	components: {
		ModalLogin
	},
	mounted() {
		this.$nextTick(function(){
			window.addEventListener("scroll", function(){
				var navbar = document.getElementById("header")
				var nav_classes = navbar.classList
				if(document.documentElement.scrollTop >= 20) {
					if (nav_classes.contains("solid-header") === false) {
						nav_classes.toggle("solid-header");
					}
				}
				else {
					if (nav_classes.contains("solid-header") === true) {
						nav_classes.toggle("solid-header");
					}
				}
			})
		})
	},
	computed: {
		account() {
			let data = this.$store.state.account.account;

			if(data && data.type == 'user') data.detail = data.user;
			else if(data && data.type == 'shipyard') data.detail = data.shipyard;
			else if(data && data.type == 'vendor') data.detail = data.vendor;

			return data;
		},
		lang() {
			return this.$store.state.lang.locale
		}
	},
	methods: {
		selectLang(val) {
			Cookie.set("Docking_Lang", val);
			this.$store.commit('SET_LANG', val);
			window.location.reload()
		},
		openLoginPopup() {
			this.$root.$emit('bv::show::modal','modalLogin')
		},
		closeLoginPopup() {
			this.$root.$emit('bv::hide::modal','modalLogin')
		},
		logout() {
			Cookie.remove("USERTYPE")
			Cookie.remove('DOCKSID');
			window.location.reload();
		}
	},
	beforeMount() {
		let userPartner = Cookie.get("USERTYPE");

			if(Cookie.get('DOCKSID')) {
					let accountApi = new accountAPI(this.$axios);

					accountApi.me().then(res => {
						this.$store.dispatch('setAccount', res.data);
					});
				}

			if(userPartner === "shipyard" || userPartner === "vendor" ) {
				this.showDockingFeature = true
			}

		}


 }

</script>

<style lang="scss">
	@import '~/assets/scss/_mixins.scss';
	@import './style.scss';
</style>

const store = {
	state: () => ({
		shipyard: null
	}),
	getters: {

	},
	mutations: {
		SET_SHIPYARD(state, shipyard) {
			state.shipyard = shipyard
		}
	},
	actions: {
		setShipyard: ({commit}, shipyard) => {
			commit('SET_SHIPYARD', shipyard)
		},
	}
}

export default store

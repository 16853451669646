<template lang="html">
  <div class="partner-dashboard-header">
    <div class="container">
      <b-navbar toggleable="lg" class="" type="dark">
        <b-navbar-brand href="/">
          <img src="/images/docking_id_logo.png" class="d-inline-block align-top header-logo" alt="DOCKING.ID LOGO"/>
        </b-navbar-brand>
        <b-navbar-brand href="/partner-dashboard"> / Partner Dashboard</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">

            <b-nav-item-dropdown class="account-menu" right>
              <!-- Using 'button-content' slot -->
              <template slot="button-content">

                <!-- <div class="expand">
                  <img src="/images/icons/expand-button.svg" alt="">
                </div> -->
                <p>
                  {{ account.detail.name  }}
                  <br>

                  <b-badge v-if="isPublish" variant="success">PUBLISHED</b-badge>

                  <b-badge v-else variant="warning">UNPUBLISH</b-badge>
                </p>
                <div class="profpic">
                  <img src="/images/icons/man.svg">
                </div>


              </template>
              <b-dropdown-item href="#">Profile</b-dropdown-item>
              <b-dropdown-item href="#" @click="logout">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

  </div>

</template>

<script>
import accountAPI from '~/api/account.js'
import Cookie from 'js-cookie'

export default {
  data:function() {
    return {
      isPublish:false
    }
  },
  methods:{
    logout() {
      Cookie.remove('DOCKSID');
      Cookie.remove("USERTYPE")
			window.location.pathname = '/'
		}
  },
  computed:{
    account() {

      let data = this.$store.state.account.account;

			if(data && data.type == 'user') data.detail = data.user;
			else if(data && data.type == 'shipyard') data.detail = data.shipyard;
      else if(data && data.type == 'vendor') data.detail = data.vendor;
      else data = {
        detail:{
          name:null
        },
        status:null
      }

			return data;
		},
  },
  beforeMount() {

    if(Cookie.get('DOCKSID')) {
			let accountApi = new accountAPI(this.$axios);

			accountApi.me().then(res => {
        this.$store.dispatch('setAccount', res.data);

        if(res.data.shipyard) {
          if(res.data.shipyard.hasActive === "ACTIVE" && res.data.shipyard.images.length > 0) {
            this.isPublish = true;
          }
        }

        if(res.data.vendor) {
          if(res.data.vendor.hasActive == 1 && res.data.vendor.images.length > 0 ) {
            this.isPublish = true;
          }
        }
			});
		}
  }
}
</script>

<style lang="scss">
	@import '~/assets/scss/_mixins.scss';
	@import './style.scss';
</style>

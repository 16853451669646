<template lang="html">
  <div class="">
    <nav class="navigation">
      <div class="container">
        <ul>

          <li v-show="showDockingFeature" >
            <a href="/partner-dashboard/shipyard-facilities">Dock Facilities & Scheduling</a>
          </li>

          <li :class="pdActive">
            <a href="/partner-dashboard">Company Profile</a>
          </li>
          <li :class="ciActive">
            <a href="/partner-dashboard/company-images">Company Images</a>
            <span class="attention">
              <img src="/images/icons/attention-sign.svg" alt="">
            </span>
          </li>
          <!-- <li :class="dpActive">
            <a href="/partner-dashboard/docking-package">Buy Package</a>
          </li> -->
          <li :class="asActive">
            <a href="/partner-dashboard/account-setting">Account Setting</a>
          </li>
          <li :class="'view-profile'">
            <a target="_blank" v-if="account.type === 'shipyard'" :href="'/galangan/profile/' + this.shipyardUrl">View Profile</a>
            <a target="_blank" v-if="account.type !== 'shipyard'" :href="'/vendor/profile/' + this.vendorUrl">View Profile</a>
          </li>
        </ul>
      </div>
    </nav>
    <DashboardUnpublishNotif v-if="displayUnpublishNotif" />
  </div>

</template>

<script>
import DashboardUnpublishNotif from "~/components/dashboard-unpublish-notif"
import Cookie from "js-cookie"
import accountAPI from "~/api/account"
import ShipyardAPI from "~/api/shipyards"

export default {
  middleware: "authentication",
  data:function() {
    return {
      shipyardUrl : null,
      vendorUrl : null,
      pdActive:'', // partner-dashboard
      dpActive:'',
      dfActive:'', // docking-feature
      ciActive:'',
      cpActive:'', // company-profile
      asActive:'', // account-setting
      showDockingFeature:false,
      isImage:false,
      isActive:false,
      displayUnpublishNotif:false,
      account: {
        email: null,
        type: null,
        status: null
      }
    }
  },
  components : {
    DashboardUnpublishNotif
  },
  methods:{
    setAllUnactive() {
      this.pdActive = ''; // partner-dashboard
      this.dfActive = ''; // docking-feature
      this.cpActive = ''; // company-profile
      this.ciActive = ''; // company-images
      this.dpActive = '';
      this.asActive = ''; // account-setting
    }

  },
  beforeMount() {

      let _this = this
      let usertype = Cookie.get("USERTYPE")
      let accountApi = new accountAPI(this.$axios);

      if(usertype === "shipyard") {
        this.showDockingFeature = true
      }

      if(Cookie.get('DOCKSID')) {

        accountApi.me().then(res => {
          this.account = {
            email: res.data.email,
            type: res.data.type,
            status: res.data.status
          };

          if(res.data.shipyard) {
            _this.shipyardUrl = res.data.shipyard.slug
            if(res.data.shipyard.images.length > 0 ) {
              _this.isImage = true

            }
            if(res.data.shipyard.hasActive === "ACTIVE" ) {
              _this.isActive = true

            }
          }

          if(res.data.vendor) {
            _this.vendorUrl = res.data.vendor.slug
             if(res.data.vendor.images.length > 0 ) {
              _this.isImage = true
            }
            if(res.data.vendor.hasActive == 1 ) {
              _this.isActive = true
            }
          }

          if(_this.isImage === false || _this.isActive === false) {
            _this.displayUnpublishNotif = true
          }



        });
      }

  },
  mounted() {
    var url = this.$route.path

    var valUrl = url.split("/").slice(-1)[0]

     //console.log(" ==> ", valUrl)

    if(valUrl === 'partner-dashboard') {
      this.setAllUnactive()
      this.pdActive = 'active'
    }
    else if(valUrl === 'shipyard-facilities') {
       this.setAllUnactive()
      this.dfActive = 'active'
    }
    else if(valUrl === 'company-images') {

      this.setAllUnactive()
      this.ciActive = 'active'
    }
    else if(valUrl === 'company-profile') {

      this.setAllUnactive()
      this.cpActive = 'active'
    }else if(valUrl === 'docking-package') {

      this.setAllUnactive()
      this.dpActive = 'active'
    }
    else if(valUrl === 'account-setting') {

      this.setAllUnactive()
      this.asActive = 'active'
    }
  }

}
</script>


<style lang="scss">
@import '~/assets/scss/_mixins.scss';
@import '~/assets/scss/partner-dashboard.scss';
@import './style.scss';
</style>

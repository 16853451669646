<template>
  <div class="default-page">
    <MainHeader />
    <div class="container" style="height:80vh">
      <h1 style="color:#fff; font-size:60px; padding-top:40vh; text-align:center;" v-if="error.statusCode === 404">
        Oops sorry, <br>
        page not found
      </h1>
      <h1 style="color:#fff; font-size:60px; padding-top:40vh; text-align:center;" v-if="error.statusCode === 500">
        Oops sorry, <br>
        Somethings went wrong at the moment
      </h1>
    </div>
    <MainFooter />
  </div>
</template>

<script>

import MainFooter from '~/components/main-footer'
import MainHeader from '~/components/main-header'

export default {
  props: ['error'],
  head () {
    return {
      script: [
        { src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js' }
      ],
      link: [
        {  rel: 'stylesheet', href: 'https://fonts.googleapis.com/css?family=Montserrat:400,500,700,800|Roboto:400,500,700' }
      ]
    }
  },

  components: {
    MainHeader,
    MainFooter
  }
}
</script>

<style lang="scss">
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6,button {
  font-family: 'Montserrat', sans-serif;
  font-weight:bold;
  letter-spacing:1px;
}


p,a,select,input,li,span {
  font-family:'roboto',sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

a {
  &:hover {
    text-decoration: none;
    color: #fa5b33;
    cursor: pointer;
  }
}

</style>
